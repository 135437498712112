import { PlanetTime, PlanetsEnum } from "./PlanetTime";
import SunCalc from "suncalc";

export default class PlanetTimeManager {
  getPlanetTimes = function (date, lat, lng) {
    let today = date;
    let yesterday = new Date(
      new Date(today.getTime()).setDate(today.getDate() - 1)
    );
    let tomorrow = new Date(
      new Date(today.getTime()).setDate(today.getDate() + 1)
    );

    let todaySuntimes = SunCalc.getTimes(today, lat, lng);
    let todaySunrise = todaySuntimes.sunrise;
    let todaySunset = todaySuntimes.sunset;

    let yesterdaySuntimes = SunCalc.getTimes(yesterday, lat, lng);
    let yesterdaySunset = yesterdaySuntimes.sunset;

    let tomorrowSuntimes = SunCalc.getTimes(tomorrow, lat, lng);
    let tomorrowSunrise = tomorrowSuntimes.sunrise;
    let tomorrowSunset = tomorrowSuntimes.sunset;

    let yesterdayNightTotal =
      todaySunrise.getTime() - yesterdaySunset.getTime();
    let todayDayTotal = todaySunset.getTime() - todaySunrise.getTime();
    let todayNightTotal = tomorrowSunrise.getTime() - todaySunset.getTime();

    let yesterdayNightInterval = yesterdayNightTotal / 12;
    let todayDayInterval = todayDayTotal / 12;
    let todayNightInterval = todayNightTotal / 12;

    let yesterdayPlanetTimes = this.planetTimesWith(
      yesterdaySunset,
      yesterdayNightInterval,
      todaySunset.getDay(),
      true
    );
    let todayPlanetTimes = this.planetTimesWith(
      todaySunrise,
      todayDayInterval,
      todaySunset.getDay(),
      false
    );
    let tomorrowPlanetTimes = this.planetTimesWith(
      todaySunset,
      todayNightInterval,
      tomorrowSunset.getDay(),
      true
    );

    let planetTimes = yesterdayPlanetTimes
      .concat(todayPlanetTimes)
      .concat(tomorrowPlanetTimes);
    let onlyTodayPlanetTimes = planetTimes.filter(function (pt) {
      let startIsToday = pt.startDate.getDay() === date.getDay();
      let endIsToday = pt.endDate.getDay() === date.getDay();
      return startIsToday || endIsToday;
    });

    return onlyTodayPlanetTimes;
  };

  planetTimesWith = function (startDate, interval, dayOfWeek, night) {
    let startPlanets = [
      PlanetsEnum.mercury, // sunday
      PlanetsEnum.jupiter, // monday
      PlanetsEnum.venus, // tuesday
      PlanetsEnum.saturn, // wednesday
      PlanetsEnum.sun, // thursday
      PlanetsEnum.moon, // friday
      PlanetsEnum.mars, // saturday
    ];
    let startIndex = dayOfWeek;
    let startPlanet = startPlanets[startIndex];

    let times = [];
    for (var i = 0; i < 12; i++) {
      let sd = new Date(startDate.getTime() + interval * i);
      let ed = new Date(sd.getTime() + interval);
      let planetIndex = startPlanet + i + (night ? 0 : 12);
      let effectivePlanet = planetIndex % 7;
      let pt = new PlanetTime(effectivePlanet, sd, ed, night);
      times.push(pt);
    }
    return times;
  };

  mod = function (n, m) {
    return ((n % m) + m) % m;
  };
}

import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import classNames from "classnames";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/exampleStyle.js";
import AdSense from 'react-adsense';

const useStyles = makeStyles(styles);

export default function SectionActivePlanet(props) {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  if (props.hidden) {
    return (
      <div />
    );
  }
  const currentDate = new Date();
  const activePlanetTime = props.activePlanetTime;
  const activePlanetTimeProgress = (currentDate.getTime() - activePlanetTime.startDate.getTime()) * 100 / (activePlanetTime.endDate.getTime() - activePlanetTime.startDate.getTime());;

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div className={classes.title} style={{ marginBottom: 80 + "px" }}>
          <h2>Şu Anki Gezegen Saati</h2>
        </div>

        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={4} className={classes.itemGrid}>
            <AdSense.Google
              client='ca-pub-7809254436587176'
              slot='9969961690'
              style={{ display: 'block' }}
              layout='in-article'
              format='fluid'
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4} className={classes.itemGrid}>
            <Card>
              <CardBody>
                <div
                  style={{
                    marginBottom: -80 + "px"
                  }}
                />
                <img
                  src={activePlanetTime.symbolMedium}
                  alt="..."
                  className={imageClasses}
                />

                <h4 className={classes.cardTitle}>
                  {activePlanetTime.name} Saati
                      <br />
                  <small className={classes.smallTitle}>
                    {activePlanetTime.startTime} - {activePlanetTime.endTime}
                  </small>
                </h4>
                <CustomLinearProgress
                  variant="determinate"
                  color="primary"
                  value={activePlanetTimeProgress}
                />
                <p className={classes.description}>{activePlanetTime.summary}</p>
                <Link
                  to={{
                    pathname: activePlanetTime.url,
                    state: {
                      planetTime: activePlanetTime,
                      activePlanetTimeProgress: activePlanetTimeProgress
                    },
                  }}
                  className={classes.link}
                >
                  <Button color="primary" size="lg" simple>
                    Daha Fazla
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4} className={classes.itemGrid}>
            <AdSense.Google
              client='ca-pub-7809254436587176'
              slot='9060559920'
              style={{ display: 'block' }}
              layout='in-article'
              format='fluid'
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

import React from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import scriptLoader from 'react-async-script-loader'

const inputStyle = {
    marginTop: 10,
    fontWeight: 400,
    border: 0
};
const inputSeparatorStyle = {
    marginTop: 4,
    backgroundColor: '#d2d2d2',
    height: 1
};

class LocationSearchInput extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            address: '',
            selectedLatLng: props.selectedLatLng,
            onLatLngChage: props.onLatLngChange
        };
    }

    componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed }) {
        if (isScriptLoaded && !this.props.isScriptLoaded) { // load finished
            if (isScriptLoadSucceed) {
                this.initEditor()
            }
            else this.props.onError()
        }
    }

    componentDidMount() {
        const { isScriptLoaded, isScriptLoadSucceed } = this.props
        if (isScriptLoaded && isScriptLoadSucceed) {
            this.initEditor()
        }
    }

    initEditor() {
        this.setState({ isScriptLoaded: true })
    }

    handleChange = address => {
        this.setState({ address });
    };

    handleSelect = address => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => this.state.onLatLngChage(latLng))
            .catch(error => console.error('Error', error));
    };

    render() {
        if (!this.state.isScriptLoaded) {
            return (<div />);
        }
        return (
            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                        <input style={inputStyle}
                            {...getInputProps({
                                placeholder: 'Konum ara...',

                            })}
                        />
                        <div style={inputSeparatorStyle} />
                        <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        );
    }
}

export default scriptLoader(
    [
        'https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyCIes5te-JAf_pwSn_Mv8UjxhElcrZmvR4',
        'https://cdnjs.cloudflare.com/ajax/libs/marked/0.3.5/marked.min.js'
    ]
)(LocationSearchInput)
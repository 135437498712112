import React, { memo } from "react";
// react plugin for creating date-time-picker
import Datetime from "react-datetime";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

// @material-ui/icons
import LibraryBooks from "@material-ui/icons/LibraryBooks";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import LocationSearchInput from "views/Components/Sections/LocationSearchInput.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/javascriptStyles.js";

const useStyles = makeStyles(styles);


export default memo(function SectionLocationDatePicker(props) {
  const classes = useStyles();

  const selectedDate = props.selectedDate;
  const onDateChange = props.onDateChange;

  const currentLatLng = props.currentLatLng;
  const onLatLngChange = props.onLatLngChange;

  return (
    <div className={classes.section}>
      <div className={classes.container}>

        <GridContainer justify="center">
          <div className={classes.title}>
            <h2>Konum ve Tarih Seçimi</h2>
          </div>
          <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6} className={classes.itemGrid}>
                <InputLabel className={classes.label}>
                  Konum
                </InputLabel>
                <br />
                <FormControl fullWidth>
                  <LocationSearchInput
                    defaultLatLng={currentLatLng}
                    onLatLngChange={onLatLngChange}
                  />
                </FormControl>
              </GridItem>

              <GridItem xs={12} sm={6} md={6} className={classes.itemGrid}>
                <InputLabel className={classes.label}>
                  Tarih
                </InputLabel>
                <br />
                <FormControl fullWidth>
                  <Datetime
                    inputProps={{ placeholder: "Tarih" }}
                    defaultValue={selectedDate}
                    dateFormat={true}
                    timeFormat={false}
                    onChange={onDateChange}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
});

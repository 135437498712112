import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js"
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/planetPage.js";
import { PlanetTime, PlanetsEnum } from "./PlanetTime";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

const useStyles = makeStyles(styles);

export default function PlanetPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const { handle } = props.match.params;

  var planetTime = null;
  if (props.location.state && "planetTime" in props.location.state) {
    planetTime = props.location.state.planetTime;
  } else {
    switch (handle) {
      case "saturn-saati":
        planetTime = new PlanetTime(PlanetsEnum.saturn, "", "", false);
        break;
      case "jupiter-saati":
        planetTime = new PlanetTime(PlanetsEnum.jupiter, "", "", false);
        break;
      case "mars-saati":
        planetTime = new PlanetTime(PlanetsEnum.mars, "", "", false);
        break;
      case "gunes-saati":
        planetTime = new PlanetTime(PlanetsEnum.sun, "", "", false);
        break;
      case "venus-saati":
        planetTime = new PlanetTime(PlanetsEnum.venus, "", "", false);
        break;
      case "merkur-saati":
        planetTime = new PlanetTime(PlanetsEnum.mercury, "", "", false);
        break;
      case "ay-saati":
        planetTime = new PlanetTime(PlanetsEnum.moon, "", "", false);
        break;

      default:
        return (<Redirect to="/" />);
        break;
    }
  }

  return (
    <div>
      <Helmet>
        <title>Gezegen Saatleri | {planetTime.name} Saati</title>
        <link rel="canonical" href={"https://gezegensaati.com/" + planetTime.url} />
        <meta name="description" content={planetTime.summary} />
        <meta property="og:title" content={"Gezegen Saatleri | " + planetTime.name + " Saati"} />
        <meta property="og:image" content={planetTime.symbol} />
      </Helmet>
      <Header
        color="transparent"
        brand="Ana Sayfa"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/bg-planet.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.planet}>
                  <div>
                    <img
                      src={planetTime.symbol}
                      alt="..."
                      className={imageClasses}
                    />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>{planetTime.name} Saati</h3>
                    <h6>
                      {planetTime.startTime} - {planetTime.endTime}
                    </h6>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <h5>{planetTime.explanation}<br /><br /><br /><br /></h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import classNames from "classnames";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/exampleStyle.js";

const useStyles = makeStyles(styles);

export default function SectionPlanetList(props) {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  const planetTimeList = props.planetTimeList;
  const selectedDate = props.selectedDate;

  return (
    <div className={classes.section}>
      <div className={classes.container}>

        <div className={classes.title}>
          <h2 style={{ marginTop: 10 + "px" }}>
            {selectedDate.toString("dd.MM.yyyy")} Gezegen Saatleri
          </h2>
        </div>

        <GridContainer justify="center">
          {planetTimeList.map((pt) => {
            return (
              <GridItem xs={6} sm={4} md={2} className={classes.itemGrid}>
                <Link
                  to={{
                    pathname: pt.url,
                    state: {
                      planetTime: pt,
                    },
                  }}
                  className={classes.link}
                >
                  <img
                    src={pt.symbolSmall}
                    alt="..."
                    className={imageClasses}
                  />
                  <h6>{pt.name} Saati</h6>
                </Link>
                <div
                  style={{
                    marginTop: -15 + "px"
                  }}
                />
                <small style={{ fontStyle: "italic" }}>
                  {pt.startTime} - {pt.endTime}
                </small>
                <div
                  style={{
                    marginTop: 10 + "px",
                    marginBottom: 50 + "px",
                    marginRight: 50 + "px",
                    marginLeft: 50 + "px",
                  }}
                />
              </GridItem>
            );
          })}
        </GridContainer>
      </div>
    </div>
  );
}

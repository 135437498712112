import React, { useState, useCallback } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionActivePlanet from "./Sections/SectionActivePlanet.js";
import SectionPlanetList from "./Sections/SectionPlanetList.js";
import SectionLocationDatePicker from "./Sections/SectionLocationDatePicker.js";

import PlanetTimeManager from "views/PlanetPage/PlanetTimeManager.js";
import { PlanetTime } from "views/PlanetPage/PlanetTime";
import { PlanetsEnum } from "views/PlanetPage/PlanetTime";

import { Helmet } from "react-helmet";

import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);

export default function Components(props) {
  const classes = useStyles();
  const { ...rest } = props;

  // set up current location tracking
  const [currentLatLng, setCurrentLatLng] = useState({
    lat: 41,
    lng: 29
  });

  navigator.geolocation.getCurrentPosition(function (position) {
    setCurrentLatLng({ lat: position.coords.latitude, lng: position.coords.longitude })
  });

  // set up selected location tracking
  const [selectedLatLng, setSelectedLatLng] = useState(currentLatLng);
  const onLatLngChange = useCallback(latLng => {
    setSelectedLatLng(latLng);
  });


  const currentDate = new Date();
  const tomorrow = new Date(currentDate);
  tomorrow.setDate(currentDate.getDate() + 5);

  const [selectedDate, setSelectedDate] = useState(currentDate);
  const onDateChange = useCallback(e => setSelectedDate(new Date(e.format())));

  const planetTimeManager = new PlanetTimeManager();
  const planetTimeList = planetTimeManager.getPlanetTimes(selectedDate, selectedLatLng.lat, selectedLatLng.lng);
  var activePlanetTime = new PlanetTime(PlanetsEnum.saturn, "", "", false);
  const activePlanetTimeList = planetTimeList.filter((pt) => {
    return pt.startDate <= currentDate && pt.endDate >= currentDate;
  });

  var activePlanetTimeProgress = 0;
  var hideActivePlanet = true;
  if (activePlanetTimeList.length === 1) {
    hideActivePlanet = false;
    activePlanetTime = activePlanetTimeList[0];
    activePlanetTimeProgress = (currentDate.getTime() - activePlanetTime.startDate.getTime()) * 100 / (activePlanetTime.endDate.getTime() - activePlanetTime.startDate.getTime());
  }

  return (
    <div>
      <Helmet>
        <title>Gezegen Saatleri</title>
        <link rel="canonical" href="https://gezegensaati.com" />
        <meta name="description" content="Gerçekte hepimiz, astrolojik etkileri almakta ve onların bizi yönlendirmesine göre yaşamaktayız; ama bunun farkında değiliz..." />
        <meta property="og:title" content="Gezegen Saatleri" />
        <meta property="og:image" content={require("assets/img/bg-home.jpg")} />
      </Helmet>
      <Header
        brand="Ana Sayfa"
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax image={require("assets/img/bg-home.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>Gezegen Saatleri</h1>
                <h5 className={classes.subtitle} style={{ fontStyle: "italic" }}>
                  Gerçekte hepimiz, astrolojik etkileri almakta ve onların bizi yönlendirmesine göre yaşamaktayız; ama bunun farkında değiliz...
                </h5>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionLocationDatePicker
          selectedDate={selectedDate}
          onDateChange={onDateChange}
          selectedLatLng={selectedLatLng}
          onLatLngChange={onLatLngChange}
        />
        <SectionActivePlanet hidden={hideActivePlanet} activePlanetTime={activePlanetTime} />
        <SectionPlanetList selectedDate={selectedDate} planetTimeList={planetTimeList} />
      </div>
    </div>
  );
}

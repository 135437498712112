import datejs from "datejs";

export let PlanetsEnum = Object.freeze({
  saturn: 0,
  jupiter: 1,
  mars: 2,
  sun: 3,
  venus: 4,
  mercury: 5,
  moon: 6,
});

export class PlanetTime {
  constructor(planet, startDate, endDate, night) {
    this.planet = planet;
    this.startDate = startDate;
    this.endDate = endDate;
    this.night = night;
    this.startTime = this.startDate.toString("HH:mm");
    this.endTime = this.endDate.toString("HH:mm");
    switch (this.planet) {
      case PlanetsEnum.saturn:
        this.symbol = require("assets/img/planets/saturn.jpg");
        this.symbolSmall = require("assets/img/planets/saturn_small.jpg");
        this.symbolMedium = require("assets/img/planets/saturn_medium.jpg");
        this.name = "Satürn";
        this.url = "saturn-saati";
        this.summary =
          "Ağır bir saattir. Genellikle sebepsiz bir sıkıntı ve karamsarlık hissedilir.  Sabır ve dikkat gerektiren...";
        this.explanation =
          'Ağır bir saattir. Genellikle sebepsiz bir sıkıntı ve karamsarlık hissedilir. Sabır ve dikkat gerektiren işler yapılabilir. Ancak uzun sürecek işlere Satürn saatinde başlamak, işinizin devamı süresince, pek çok zorluklar, gecikmeler ve engellerle karşılaşmaya zemin hazırlar. Örneğin başlayıp da bir türlü bitiremediğiniz, defalarca sökmek zorunda kaldığınız bir örgünüz, dikişiniz vb. işlerinize çok büyük bir olasılıkla bu saatte başlanmıştır.\n\nYolculuk yapmak için de uygun değildir. Hele uzun bir yolculuğunuzun başlangıcı bu saate denk gelmişse, yollar uzar da uzar bir türlü bitmek bilmez.\n\nTabi ki, bu saatte yapılması uygun işler de vardır. Kitap okumak, düşünmek, belli konularda konuşmak, fikir tartışmaları yapmak son derece faydalı sonuçlar verir. Öğrenciler, zor anladıkları, dikkatlerini toplayamadıkları derslerine bu saatte çalışmalarının çok daha faydalı olduğunu göreceklerdir.\n\nAyrıca anlaşmakta zorlandığınız kişilerle yüzleşmeniz gerekliyse ama tatsız sonuçlar doğmasından korkuyorsanız bir de bu saatte konuşmayı deneyin. Bu saatin ağır ve kısıtlayıcı etkisi, olayların nahoş sonuçlanmasını büyük bir ihtimalle engelleyecektir.\n\nBu saat, alışveriş yapmak için de uygun değildir. İstemediğiniz şeyleri alarak sonradan, "Ah keşke bunu almasaydım!" diyebilirsiniz. Ancak daha önceden karar vermiş olmak şartıyla, emlak veya toprakla ilgili ticaretler yapabilirsiniz. Bina yapımı, inşaat işleri için uygundur.';
        break;
      case PlanetsEnum.jupiter:
        this.symbol = require("assets/img/planets/jupiter.jpg");
        this.symbolSmall = require("assets/img/planets/jupiter_small.jpg");
        this.symbolMedium = require("assets/img/planets/jupiter_medium.jpg");
        this.name = "Jüpiter";
        this.url = "jupiter-saati";
        this.summary =
          "Hoş bir saattir. İnsanın içine rahatlık, hoşgörü, sevinç gibi duygular verir. Birtakım sıkıntı ve...";
        this.explanation =
          "Hoş bir saattir. İnsanın içine rahatlık, hoşgörü, sevinç gibi duygular verir. Birtakım sıkıntı ve problemlerinizin olduğu zamanlarda bile Jüpiter yıldızının etkilerinin güçlü olduğu saatlerde, nasıl olduğunu anlamadığınız, geçici de olsa bir rahatlama hissedersiniz. Çok bunaldığınız anda size bir nefestir.\n\nŞans gerektiren konularda yapacağınız çalışmaları bu saatlere denk getirirseniz daha iyi sonuçlar elde edersiniz. Bir de, size özel diğer astrolojik etkiler de uygunsa, turnayı gözünden vurdunuz demektir; yarışmalara katılmak, piyango bileti almak veya başka konularda şansınızı denemek gibi.\n\nİbadet yapmak, zikir çekmek, dini bilgilerinizi genişletmek, tefekkür etmek, öğrenmek, bilgi alışverişi yapmak veya ders çalışmak için de uygun bir saattir. Anlaşmazlıkları, ters giden işleri ve ilişkileri düzeltmek için girişimlerde bulunmaya da uygundur.\n\nSevgi, muhabbet, dostluk, arkadaşlık kurma saatidir. Arkadaşlarınızla toplandığınız bir zaman, Jüpiter saatine sıra geldiğinde; havanız şenlenir, şakalaşmalar, eğlenceler artar.\n\nÇarşı - pazar alışverişi yapmak, sevdiklerinize hediye seçmek gibi konularda iyi kararlar vermenize yardım eder. Bu saatte yapılan alışverişlerden genellikle daha sonra pişmanlık duyulmaz.\n\nYolculuğa çıkmak, araba kullanmak için en uygun saatler olan AY ve MERKÜR saatlerine zamanınız uymadıysa, 3. cü tercih olarak Jüpiter saatini seçebilirsiniz.\n\nEğitim ve öğrenim için, öğrencilerin öğretmenlere veya kurslara gitmesine, ders çalışmasına son derece müsait bir saattir.\n\nBaşlangıç yapmak için de uygundur. Bir işe başlamak istiyorsanız, Jüpiter saatini tercih edebilirsiniz.";
        break;

      case PlanetsEnum.mars:
        this.symbol = require("assets/img/planets/mars.jpg");
        this.symbolSmall = require("assets/img/planets/mars_small.jpg");
        this.symbolMedium = require("assets/img/planets/mars_medium.jpg");
        this.name = "Mars";
        this.url = "mars-saati";
        this.summary =
          "Cesaret, acelecilik, çabuk parlama ve aksiliklere dönük güçlü bir enerji verir. Kolay halledilebilecek bir...";
        this.explanation =
          'Cesaret, acelecilik, çabuk parlama ve aksiliklere dönük güçlü bir enerji verir. Kolay halledilebilecek bir mesele bu saate denk gelmişse, istenmeden tatsız gelişmelere yol açar. Olabilecek işi imkansız hale getirir ki, nasıl böyle olabildiğine şaşar kalırsınız. Bu saatte artan vehim gücü ile insanlar istemedikleri şeyleri yapabilirler. Bu tesir geçtikten sonra, normal düşünmeye başlayınca da, "Ben bunu nasıl böyle yaptım? Pişmanım!" diyebilirler.\n\nBozuşmalara, insanların aralarının açılmasına, ağız kavgası veya tabancalı, bıçaklı, yumruklu kavgalara çoğunlukla bu saatte rastlanır. Beşeri ilişkiler sert, kırıcı ve tartışmaya dönüktür.\n\nCuma namazı saati öncesi daima Mars saati olduğu için, bu saatte birşey yapılmaması ve ilişkilerde dikkatli olunması gerekir. Cumaya hazırlanmak, sessiz kalmak iyi olur.\n\nAyrıca cesaret ve güç verir. Bunun ne yönde kullanılacağı önemlidir. İbadet edilmesi tavsiye edilir. Diğer zamanlarda yapmağa cesaret edemediğiniz, faydalı şeyleri Mars saatinde kolaylıkla yapabilirsiniz.\n\nAmeliyat, diş çektirmek, kan aldırmak, iğne olmak, tırnak kesmek gibi tıbbi konularda hem yapana hem de yaptırana kolaylık sağlar.\n\nSpor, jimnastik, futbol, yürüyüş, avcılık veya fiziki güç gerektiren işleri yapmaya son derece uygundur.\n\nTrafiğe çıkmak, araba kullanmak için, dikkatli olmak şartıyla uygundur; çünkü bu saatte araba kullanırken insan hızlı gitme isteği duyabilir. Hareketler çabuk ve dikkatsizce olabileceğinden tehlike ile karşılaşma riski doğar.\n\nOrtaklıklar ve iş ilişkilerinin, özellikle kritik durumların Mars saatine denk gelmemesi herkes için faydalı olacaktır.';
        break;

      case PlanetsEnum.sun:
        this.symbol = require("assets/img/planets/sun.jpg");
        this.symbolSmall = require("assets/img/planets/sun_small.jpg");
        this.symbolMedium = require("assets/img/planets/sun_medium.jpg");
        this.name = "Güneş";
        this.url = "gunes-saati";
        this.summary =
          "Egoların güçlendiği, kendine güvenin arttığı, benliklerin kabardığı bir saattir. Gerek bu yönü ile gerekse sahip...";
        this.explanation =
          "Egoların güçlendiği, kendine güvenin arttığı, benliklerin kabardığı bir saattir. Gerek bu yönü ile gerekse sahip olduğu güçlü enerji potansiyeli dolayısıyla, özellikle trafik için son derece uygunsuz bir saattir. Dikkat edilirse, bu saatte trafikte çok belirgin bir karışıklık görülür.\n\nBu nedenle trafik kazalarına en çok Güneş saatinde rastlanır. Tespitlere göre her 10 kazadan en az 8' i bu saatte meydana gelmiştir.\n\nİbadet yapmak, dua, zikir ve tefekkür gibi çalışmalar için çok uygundur. Daha önce de belirttiğim gibi, dinimizin son derece önemle üzerinde durduğu cuma namazı vakti daima güneş saatine denk gelmektedir.\n\nNişan, düğün gibi mutlu beraberliklerin kurulmasına, söz kesmeye uygundur. Yüzleşmelere, itirafta bulunmaya, yüksek makamlardan talepte bulunmaya, istekleri arzetmeye son derece müsait bir saattir. Olmasını istediğiniz bir iş için bir makama başvurmanız gerekiyorsa Güneş saatini tercih etmek yararlıdır. Kolaylık verir, işlerin yolunda gitmesine yardım eder. Çünkü idrakların daha iyi çalıştığı bir saattir.\n\nDostlarla bir arada bulunmak, büyüklerin gönlünü almak, saygı göstermek, çocuklarla ilgilenmek, eş dost ziyaretleri yapmak gibi şeyler de bu saatin özelliklerine ters düşmeyen konulardandır.";
        break;

      case PlanetsEnum.venus:
        this.symbol = require("assets/img/planets/venus.jpg");
        this.symbolSmall = require("assets/img/planets/venus_small.jpg");
        this.symbolMedium = require("assets/img/planets/venus_medium.jpg");
        this.name = "Venüs";
        this.url = "venus-saati";
        this.summary = "Aşk, meşk, sevme ve sevilme, musiki saatidir. Sevgi duyguları ve romantiklik...";
        this.explanation =
          "Aşk, meşk, sevme ve sevilme, musiki saatidir. İnsana hoş bir rahatlama ve gevşeklik verir. Sevgi duyguları ve romantiklik verir. Sevdiğinize duygularınızı sözle veya hareketlerle en güzel ifade edebileceğiniz saattir. Sevdiğiniz kişiye bunu söylemeye cesaretiniz yoksa bu saatte deneyebilirsiniz. Eşler arasındaki dargınlıkları düzeltebilirsiniz.\n\nMüzik dinlemek, enstürman çalmak, beste yapmak, musiki toplantıları yani her türlü müzik etkinlikleri için çok iyi enerji bulabileceğiniz saatlerdir. Ayrıca misafirlikler, neşeli ve dostça vakit geçirmek, sevişmek için uygundur.\n\nNişan, nikah, düğün toplantıları veya bu tip başlangıçlara atılacak adımlar için iyi bir zamandır.\n\nGüzeli görme, seçme, yaşama zamanıdır. Sanat etkinlikleri, resim, heykel vb. çalışmalarına, moda, süsleme, dekarasyon işlerine uygundur. Bu saatte evinizi dekore edebilir, güzel giysiler satın alabilirsiniz. Kuaföre gitmek, saç yaptırmak, makyaj yapmak için de iyidir.\n\nJüpiterden sonra gelen şans ve mutluluk saati, Venüs saatidirdir. Bu tip işlerin dışında güç, cesaret, dikkat ve sabır isteyen işler için uygun değildir.\n\nÖzellikle trafik ve yolculuk için de pek uygun bir saat olmadığı tespit edilmiştir. İnsanların romantikleşmesine ve gevşemesine sebep olduğu için kaza ve tehlike riski artmaktadır.\n\nKısaca bu saatlerde zevk veren işlerle ve sanatsal işlerle meşgul olmak daha uygundur.";
        break;

      case PlanetsEnum.mercury:
        this.symbol = require("assets/img/planets/mercury.jpg");
        this.symbolSmall = require("assets/img/planets/mercury_small.jpg");
        this.symbolMedium = require("assets/img/planets/mercury_medium.jpg");
        this.name = "Merkür";
        this.url = "merkur-saati";
        this.summary =
          "Mantık, zeka ve düşünme potansiyelini kuvvetlendiren bir enerji verir. Özellikle de beyin haritanızda...";
        this.explanation =
          "Mantık, zeka ve düşünme potansiyelini kuvvetlendiren bir enerji verir. Özellikle de beyin haritanızda Merkür güçlü bir konumdaysa, örneğin İkizler veya Başak burcundansanız Merkür saatlerinde süper beyin olmaya namzetsiniz demektir.\n\nKitap okumak, hesap yapmak, matematiksel konular ile ilgilenmek, bulmaca çözmek, yarışmalara katılmak ve zeka oyunlarında daha başarılı olmak için uygun bir saattir. Bu saatte konuşmak, sohbet etmek, konferans vermek daha rahat ve zevkli olur.\n\nMektup yazmak zor geliyorsa bir de bu saatte yazmayı deneyin. El mahareti isteyen işlerle uğraşmak, yemek yapmak, tamirat, dikiş, nakış, örgü işleri için çok uygun bir zamandır; işleriniz hem rahat ilerler hem de çabuk biter.\n\nÇözmekte zorlandığınız konulara bu saatte çok kolay ve mantıklı çözüm yolları bulabilirsiniz. İş görüşmelerine, yaratıcılık, isteyen konularla ilgilenmeye müsaittir.\n\nİsabetli, kolay ve yorulmadan alışveriş yapmak, satıcılık, pazarlamacılık için birebirdir.\n\nEğer yolculuğa çıkmak için programınız Ay saatine denk gelmiyorsa ikinci tercihiniz Merkür saati olsun. Trafik için de iyidir.\n\nEğer öğrenciler derslerini daha rahat, zorlanmadan ve çabuk öğrenmek istiyorlarsa Merkür saatlerini ders çalışmak için ayırmalıdırlar.\n\nAncak şu hususu da dikkate almak gerekir; Merkür yılda 3 kez Rotara girer. Bu süre zarfında etkileri bu kadar verimli değildir. Özellikle yanlış karar almaya ve sonradan pişman olmanıza neden olur. Bu nedenle Rötar dönemlerinde yeni kararlar almayın ve yeni bir işe başlamayın.";
        break;

      case PlanetsEnum.moon:
        this.symbol = require("assets/img/planets/moon.jpg");
        this.symbolSmall = require("assets/img/planets/moon_small.jpg");
        this.symbolMedium = require("assets/img/planets/moon_medium.jpg");
        this.name = "Ay";
        this.url = "ay-saati";
        this.summary =
          "Yüksek pozitif enerji veren bir saattir, işlere tezlik ve çabukluk verir. Örneğin; Pazartesi günü güneşin...";
        this.explanation =
          "Yüksek pozitif enerji veren bir saattir, işlere tezlik ve çabukluk verir. Örneğin; Pazartesi günü güneşin ilk ışıkları ile başladığınız ev işleriniz, yazdığınız yazılar veya başladığınız yolculuklar çok rahat biter ve nasıl olduğunu anlamazsınız.\n\nÖzellikle yeni ay zamanlarındaki verimli enerji, hem kendimizi daha zinde hissettirir, hem de bu zamanda dikilen bitkilerin iyi yetişmelerini sağlar.\n\nYeni Ay dönemlerinde ve Ay saatlerinde yapılan pasta, börek, mayalı hamurlar çok güzel kabarır.\n\nSeyahat yapmak, trafiğe çıkmak, direksiyon kullanmak için de uygundur. Eğer uzun bir seyahate çıkacaksanız yolculuğa Ay saati içinde başlamanız size kolaylık getirecektir.\n\nPostaya mektup vermek, koli, paket göndermek veya bir yere ulaştırmak istediğiniz şeyleri bu saatte göndermek çok daha iyidir. Ticaret yapmak, iş ilişkileri, ortaklık kurmak, alışveriş yapmak için de uygun bir saattir.\n\nYeni bir şeye başlamak için Ay saatini seçmek, işinizin çabuk ve rahat gitmesini sağlar.\n\nGönül, nişan, nikah, düğün işlerine uygundur. İbadet, dua, zikir çalışmaları yapmak, namaz kılmak için bu saatler tercih edilmelidir.\n\nSpor yapmak için de çok uygundur. Bunun için iyi bir enerji sağlar.\n\nYeni başladığınız işlerin ilkini JÜPİTER gününün AY saatine uydurursanız çok faydasını görürsünüz.\n\nKısaca sevilen ve hoş gelen her türlü işin yapılmasına ve yeni bir işe başlamaya çok müsait bir saattir...";
        break;

      default:
        this.symbol = null;
        this.name = "";
        this.explanation = "";
        break;
    }
  }
}

import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import Parallax from "components/Parallax/Parallax.js";
import { Link } from "react-router-dom";
import { Info } from "@material-ui/icons";

import { Helmet } from "react-helmet";

import styles from "assets/jss/material-kit-react/views/planetPage.js";

const useStyles = makeStyles(styles);

export default function PlanetPage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <Helmet>
        <title>Gezegen Saatleri Ve Günleri Nedir?</title>
        <link rel="canonical" href="https://gezegensaati.com/info" />
        <meta name="description" content="Gerçekte hepimiz, astrolojik etkileri almakta ve onların bizi yönlendirmesine göre yaşamaktayız; ama bunun farkında değiliz..." />
        <meta property="og:title" content="Gezegen Saatleri Ve Günleri Nedir?" />
        <meta property="og:image" content={require("assets/img/bg-info.jpg")} />
      </Helmet>
      <Header
        color="transparent"
        brand="Ana Sayfa"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      >
        <Link
          to={{
            pathname: '/info'
          }}
          className={classes.navLink}
        >
          <Info className={classes.icons} /> BİLGİ
        </Link>
      </Header>
      <Parallax small filter image={require("assets/img/bg-info.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12}>

              </GridItem>
            </GridContainer>
            <div className={classes.planet}>
              <div className={classes.title}>
                <h3 className={classes.title}>Gezegen Saatleri Ve Günleri Nedir?</h3>
              </div>
            </div>
            <div className={classes.description}>
              <h5>Genel olarak hepimizin ortak dileği ve amacı, olabildiğince kaliteli bir hayat sürdürebilmek.
              Her şeyin kalitesi olduğu gibi, içinde bulunduğumuz zamanın da bir kalitesi olduğu söylenir...
              <br />
                <br />
              Gün içinde değişen ruh hallerimiz ile birlikte bazı işlerimizin çok hızlı sonuçlanması söz konusu olurken kimi zaman bitmek bilmeyen bir çarkın içine girdiğimizi hissederiz.
              Nedensiz mutsuzluklarla beraber, bir anda gelen neşe duygusu ile günümüz aydınlanabilir.
              Saat saat değişen modumuzun en büyük sebeplerinden biri gün boyu değişen gezegen saatlerinin etkisi altında olmamızdır.
              <br />
                <br />

              Gezegenlerin gün ve  saat üzerindeki etkileri oldukça eski dönemlerden beri kadim alimlerin dikkatini çekmiştir.
              Çok önemli Arap astrologları tarafından gözlem yapılarak incelenen ve bizzat kendi edindikleri tecrübeler ile toplanan bilgilerin kitap halinde derlenmesi
              sonucunda bu bilgiler günümüze kadar  gelmiştir.
              <br />
                <br />

              Eski zamanlardan bugüne kadar yapılan gözlemler ve bilgilere göre yaşadığımız her günün ve saatin bir sınıflandırması var.
              Bu sınıflandırmalar ışığında, insanlar güneş burçlarına göre hangi gün ve saatlerin kendilerine daha iyi geldiğini,
              hangi gün ve saatlerde neler yapıp yapmamaları gerektiğini deneyimleyerek bu bilgileri nesillere aktarmışlar.

              <br />
                <br />


              Buna göre;
              <br />
                <ul>
                  <li>Güneş (Şems): Aslan burcu – Pazar günü</li>
                  <li>Ay (Kamer):Yengeç burcu – Pazartesi günü</li>
                  <li>Mars (Merih): Koç ve Akrep burçları – Salı günü</li>
                  <li>Merkür (Utarit): İkizler ve Başak burcu – Çarşamba günü</li>
                  <li>Jüpiter (Müşteri):Yay ve Balık burçları – Perşembe günü</li>
                  <li>Venüs (Zühre): Boğa ve Terazi burcu–Cuma günü</li>
                  <li>Satürn (Zuhal):Kova ve Oğlak burçları – Cumartesi günü</li>
                </ul>
                <br />
              Bir gün, önce gecesiyle başlar ve gündüzüyle devam eder. Örneğin cuma günü, perşembe gününün günbatımı ile başlar.
              Günlerin gezegenleri ise içinde bulunduğumuz günün ilk ışıklarının yani gün doğumunun denk geldiği saate göre belirlenir.
              Her farklı günün gün doğumuna ise istisnasız aynı gezegen denk gelir.
              <br />
                <br />


              Örneğin; Pazar akşamı, Pazar günü güneşin batışından itibaren Pazartesi gecesi başlamış demektir. Aynı şekilde, Pazartesi akşamı Salı gecesidir, Salı akşamı Çarşamba gecesidir v.s...
              <br />
                <br />
              Herhangi bir gezegen saati dilimi tüm burçları etkilemekle birlikte; eğer o yıldız güneş burcunuzun yıldızı ise etkilerini daha yoğun hissedebilirsiniz.
              <br />
                <br />
              Genel olarak, hergün ve hergece hangi gezegen saati ile başlıyorsa onun tesirinde olmakla birlikte, diğer saat dilimlerinde de sıra ile bir yıldızın kuvvetli tesirlerini alır.
              Gündüz beyinlerimiz bu tesirleri aldığı ve bunun neticeleri yaşandığı gibi; tecrübeler göstermiştir ki; gece de beyin bu tesirleri almağa devam etmektedir...
              <br />
                <br />

              Örneğin, gece uyurken gördüğümüz rüya;

              Mars saatine denk gelmişse, şiddet ve sex ağırlıklı olur. Veya Jüpiter saatinde neşeli, hoş rüyalar görmek mümkündür. Ay saatinde ise romantik veya duygusal rüyalar görebiliriz.
              Aynı şekilde; ölüm sonrasında devam eden ruh bedenli hayatta da kişi bu tesirleri almağa devam eder, aynen burçlardan gelen tesirleri aldığı gibi...
              Kıyamete kadar...

              <br />
                <br />
              Kısaca; gerek burçlardan, gerek yıldızlardan gelen tesirler dünyada yaşanırken de, ölüm sonrasında da kendilerini daima hissettireceklerdir...
              <br />
                <br />
Gezegen saatlerinin hayatınızı daha iyi yönlendirmesi dileğiyle...
<br />
                <br /><br />
                <br />
              </h5>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
